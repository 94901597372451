<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <ul class="nav nav-pills navtab-bg nav-justified mb-3">
                <li
                    v-for="(type, index) in types"
                    :key="index"
                    class="nav-item"
                >
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: activeType === type
                        }"
                        @click.prevent="changeType(type)"
                    >
                        {{ type | startCase }}
                    </a>
                </li>
            </ul>

            <awesome-table
                :id="
                    activeType === 'orthotics'
                        ? 'products'
                        : 'secondary-products'
                "
                :key="activeType"
                :labels="labels"
                :items="products"
                :options="{
                    filters: true,
                    columnsSelector: true,
                    isCard: false
                }"
                :pagination="pagination"
                :filters="filters"
                @add-button-click="
                    $router.push(
                        `/products/${
                            activeType === 'orthotics' ? 'new' : 'new-secondary'
                        }`
                    )
                "
                @row-click="$router.push(getRowCLickPath($event))"
                @mouse-wheel-click="$openNewTab(getRowCLickPath($event))"
                @pagination-change="onPaginationChange"
                @filter-change="onFilterChange"
            >
                <template #[`items.actions`]="{ item }">
                    <a
                        href="#"
                        class="action-icon"
                        @click.stop.prevent="removeProduct(item.id)"
                    >
                        <i class="far fa-trash-alt" />
                    </a>
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Products',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Product List',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Product List',
                    active: true
                }
            ],
            products: [],
            types: ['orthotics', 'other'],
            activeType: 'orthotics',
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters)
        };
    },

    computed: {
        labels() {
            const typeLabel = {
                value: 'type',
                filter: 'startCase',
                type: 'enum',
                enumOptions: [
                    {
                        text: 'Cream',
                        value: 'cream'
                    }
                ]
            };

            return [
                'name',
                { value: 'price', filter: 'variantFixedPrice' },
                { value: 'sale_price', filter: 'variantFixedPrice' },
                ...(this.activeType === 'other' ? [typeLabel] : []),
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'actions',
                    sortable: false,
                    filterable: false
                }
            ];
        }
    },

    mounted() {
        const { type } = this.$route.query;

        if (this.types.includes(type)) {
            this.activeType = type;
        }

        this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            deleteProduct: 'products/delete',
            getSecondaryProducts: 'secondaryProducts/index',
            deleteSecondaryProduct: 'secondaryProducts/delete'
        }),

        async fetchProducts() {
            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } =
                    this.activeType === 'orthotics'
                        ? await this.getProducts(options)
                        : await this.getSecondaryProducts(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }   

                this.products = rows;
                this.pagination.pages = Math.ceil(
                    count / this.pagination.perPage
                );
                this.pagination.total = count;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        getRowCLickPath(item) {
            return `/products/${item.id}/${
                this.activeType === 'orthotics' ? 'edit' : 'edit-secondary'
            }`;
        },

        async removeProduct(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this product?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                this.activeType === 'orthotics'
                    ? await this.deleteProduct(id)
                    : await this.deleteSecondaryProduct(id);

                this.products = this.products.filter(
                    item => item.id !== id
                );

                this.$toaster('Product has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        changeType(type) {
            this.activeType = type;

            this.pagination = {
                ...this.pagination,
                currentPage: 1
            };

            this.fetchProducts();
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchProducts();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchProducts();
        }
    }
};
</script>
