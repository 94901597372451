var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('ul',{staticClass:"nav nav-pills navtab-bg nav-justified mb-3"},_vm._l((_vm.types),function(type,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                        active: _vm.activeType === type
                    },attrs:{"href":"#","data-toggle":"tab","aria-expanded":"false"},on:{"click":function($event){$event.preventDefault();return _vm.changeType(type)}}},[_vm._v("\n                    "+_vm._s(_vm._f("startCase")(type))+"\n                ")])])}),0),_c('awesome-table',{key:_vm.activeType,attrs:{"id":_vm.activeType === 'orthotics'
                    ? 'products'
                    : 'secondary-products',"labels":_vm.labels,"items":_vm.products,"options":{
                filters: true,
                columnsSelector: true,
                isCard: false
            },"pagination":_vm.pagination,"filters":_vm.filters},on:{"add-button-click":function($event){return _vm.$router.push(
                    ("/products/" + (_vm.activeType === 'orthotics' ? 'new' : 'new-secondary'))
                )},"row-click":function($event){_vm.$router.push(_vm.getRowCLickPath($event))},"mouse-wheel-click":function($event){_vm.$openNewTab(_vm.getRowCLickPath($event))},"pagination-change":_vm.onPaginationChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.actions",fn:function(ref){
                var item = ref.item;
return [_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeProduct(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }